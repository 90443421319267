











































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Card, createToken } from 'vue-stripe-elements-plus';

import { Mutation, Org } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { asDefined } from '@/utils/guards';

import { MUT_SNACKBAR } from '../../store';

@Component({
  apollo: {
    orgInfo: {
      query: gql`
        query GetUsers($orgId: ID!) {
          org(id: $orgId) {
            id
            subscriptionDetails {
              accountingSeats
              walletSeats
            }
            provisionedAccountingUsers
            provisionedWalletUsers
            users {
              id
            }
          }
        }
      `,
      update(data) {
        return data.org;
      },
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
  components: {
    Card,
  },
})
export default class Billing extends BaseVue {
  @Prop({ default: false })
  public readonly!: boolean;

  public error = null as null | string;
  public activating = false;
  public isLoading = false;
  public coupon = '';
  public complete = false;
  public updateComplete = false;
  public orgInfo = {} as Partial<Org>;
  public subscribeLoading = false;
  public updateLoading = false;
  public showUpdate = false;
  public get activeWalletSeats() {
    if (this.orgInfo.provisionedWalletUsers) {
      return this.orgInfo.provisionedWalletUsers;
    } else {
      return 0;
    }
  }

  public get activeAccountingSeats() {
    return 1;
    // NOTE [pw] This has caused nothing but confusion, so our default will just be 1 license
    // if (this.orgInfo.provisionedAccountingUsers) {
    //   return this.orgInfo.provisionedAccountingUsers;
    // } else if (this.users) {
    //   if (this.users.length < 2) {
    //     return 2;
    //   } else {
    //     return this.users.length;
    //   }
    // } else {
    //   return 2;
    // }
  }

  public get total() {
    return this.activeAccountingSeats * 250 + this.activeWalletSeats * 25;
  }

  public get subscribed() {
    return (
      this.orgInfo &&
      this.orgInfo.subscriptionDetails &&
      (this.orgInfo.subscriptionDetails.accountingSeats || this.orgInfo.subscriptionDetails.walletSeats)
    );
  }

  public get users() {
    return this.orgInfo.users;
  }

  public get org() {
    return this.$store.state.currentOrg;
  }

  public get accountingSeats() {
    if (this.subscribed && this.orgInfo.subscriptionDetails) {
      return this.orgInfo.subscriptionDetails.accountingSeats;
    } else {
      return 0;
    }
  }

  public get walletSeats() {
    if (this.subscribed && this.orgInfo.subscriptionDetails) {
      return this.orgInfo.subscriptionDetails.walletSeats;
    } else {
      return 0;
    }
  }

  public cardChange(event: any, update: unknown) {
    console.log(event);
    if (event.error) {
      this.error = event.error.message;
    } else {
      this.error = '';
    }

    if (update) {
      this.updateComplete = event.complete;
    } else {
      this.complete = event.complete;
    }
  }

  public getStripeKey(): string {
    return asDefined(process.env.VUE_APP_STRIPE_KEY);
  }

  public async pay() {
    // createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    const { token, error } = await createToken();
    if (error) {
      this.error = error.message ?? null;
    } else {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        paymentToken: token?.id,
        details: {
          accountingSeats: this.activeAccountingSeats,
          walletSeats: this.activeWalletSeats,
        },
        coupon: this.coupon === '' ? null : this.coupon,
      };
      this.subscribeLoading = true;
      const d = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $paymentToken: String!, $details: SubscriptionDetailsInput!, $coupon: String) {
            subscribe(orgId: $orgId, paymentToken: $paymentToken, details: $details, coupon: $coupon) {
              success
              error
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      this.subscribeLoading = false;
      if (d.data.subscribe.success === false) {
        this.error = d.data.subscribe.error;
      } else {
        this.isLoading = true;
        this.activating = true;
        try {
          await Promise.all([this.$apollo.queries.orgInfo.refetch(), this.$store.dispatch('updateOrgs')]);
        } finally {
          this.isLoading = false;
          this.activating = false;
        }
      }
    }
  }

  public async update() {
    // createToken returns a Promise which resolves in a result object with
    // either a token or an error key.
    // See https://stripe.com/docs/api#tokens for the token object.
    // See https://stripe.com/docs/api#errors for the error object.
    // More general https://stripe.com/docs/stripe.js#stripe-create-token.
    const { token, error } = await createToken();
    if (error) {
      this.error = error.message ?? null;
    } else {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        paymentToken: token?.id,
      };
      this.updateLoading = true;
      const resp = await this.$apollo.mutate<Pick<Mutation, 'updateSubscription'>>({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $paymentToken: String!) {
            updateSubscription(orgId: $orgId, paymentToken: $paymentToken) {
              error
              success
            }
          }
        `,
        // Parameters
        variables: vars,
      });

      this.updateLoading = false;
      const updateSubResp = asDefined(resp.data?.updateSubscription);
      if (!updateSubResp.success) {
        this.error = updateSubResp.error ?? null;
      } else {
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: this.$tc('_paymentUpdateSuccess'),
        });
        this.isLoading = true;
        this.$emit('update');
        try {
          await this.$apollo.queries.orgInfo.refetch();
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
}
